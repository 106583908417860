.f-w-500 {
    font-weight: 500 !important;
}
.f-8 {
    font-size: 8px;
}
.f-10 {
    font-size: 10px;
}
.f-11 {
    font-size: 11px;
}
.f-12 {
    font-size: 12px;
}
.f-13 {
    font-size: 13px;
}
.f-14 {
    font-size: 14px !important;
}
.f-15 {
    font-size: 15px !important;
}
.f-16 {
    font-size: 16px;
}
.f-18 {
    font-size: 18px;
}
.f-19 {
    font-size: 19px;
}
.f-20 {
    font-size: 20px;
}
.f-21 {
    font-size: 21px;
}
.f-27 {
    font-size: 27px;
}
.f-57 {
    font-size: 57px;
}
.heading-h1 {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
}
.heading-h2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.17;
}
.heading-h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.17;
}
.heading-h4 {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.13;
}
.heading-h5 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    letter-spacing: 0.1px;
}
.heading-h6 {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
}
.simple-text {
    font-size: 14px;
    line-height: 1.14;
}
.font-weight-semibold {
    font-weight: 500 !important;
}